import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useMatch, useNavigate, useParams } from "react-router-dom";

import { useRef, useState } from "react";
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";
import { ModalInput } from "../../../../../../ui/ModalInput/ModalInput";

import { ApiService } from "../../../../../../../services/ApiService";

import { useDispatchedActions } from "../../../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../../../hooks/useTranslation";

import { FormCheckBox } from "../../../../../../ui/CheckBoxes/FormCheckBox";
import { Button } from "../../../../../../ui/Buttons/Button/Button";
import { CustomModal } from "../../../../../../../components/ui/CustomModal/CustomModal";
import { errorToast } from "../../../../../../../utils/helpers/customToast.helper";
import { ButtonLoader } from "../../../../../../ui/ButtonLoader/ButtonLoader";
import { emailPattern } from "../../../../../../../utils/constants/emailPattern";
import { passwordPattern } from "../../../../../../../utils/constants/passwordPattern";

export const LoginModal = () => {
  // **Props
  const navigate = useNavigate();
  const { lang } = useParams();
  const matchCabinet = useMatch("/:lang/dashboard");
  const reCaptchaRef = useRef();
  // Dispatch
  const {
    setLoginModal,
    setRecoveryModal,
    setRegisterModal,
    setUserAuthData,
    setUseFiveDollarLogick
  } = useDispatchedActions();

  // **State
  const { fiveDollarLogick } = useSelector((state) => state.content.proxies);
  const { isLoginModalOpened } = useSelector((state) => state.header);
  const { isCaptchaEnabled, content } = useSelector((state) => state.content);
  const { isAuth } = useSelector((state) => state.content.user);
  const [isLoading, setIsLoading] = useState(false);

  const {
    cabinet: { profile },
    header: { modalCabinet, formElem },
    notifications
  } = useTranslation();

  const methods = useForm({
    mode: "onChange"
  });

  const { errors } = methods.formState;

  // **local State
  const [disabled, setDisabled] = useState(false);

  const handleLogin = async (captchaToken) => {
    setDisabled(true);
    setIsLoading(true);
    const data = methods.getValues();
    try {
      const params = {
        username: data.email,
        password: data.password,
        rememberMe: data.remember,
        captchaToken: captchaToken ? captchaToken : null
      };
      const response = await ApiService.login(params);

      if (response.response && response.response.status !== 200) {
        setDisabled(false);
        throw notifications.loginError;
      }

      if (response?.status === 200) {
        const userProfileResponse = await ApiService.userAuthUserProfile();
        if (
          userProfileResponse.response &&
          userProfileResponse.response.status !== 200
        ) {
          setDisabled(false);
          throw notifications.loginError;
        }
        if (userProfileResponse?.data) {
          localStorage.setItem(
            "client_profile",
            JSON.stringify(userProfileResponse?.data)
          );
          setLoginModal(!isLoginModalOpened);
          setUserAuthData({
            isAuth: true,
            email: userProfileResponse?.data.email
          });

          //five Dollar Logick
          if (fiveDollarLogick?.enabled && fiveDollarLogick?.use) {
            setDisabled(false);
            navigate(`/${lang}/dashboard/?tab=balance`);
          } else {
            setDisabled(false);
            if (!matchCabinet) {
              navigate(`/${lang}/dashboard/`);
            }
          }
        } else {
          setDisabled(false);
          throw notifications.loginError;
        }
      } else {
        setDisabled(false);
        throw notifications.loginError;
      }
    } catch (err) {
      errorToast(err.message || err, lang);
      setDisabled(false);
    } finally {
      setIsLoading(false);
      if (reCaptchaRef.current) {
        reCaptchaRef.current.reset();
      }
    }
  };

  const onSubmit = () => {
    if (
      isCaptchaEnabled &&
      reCaptchaRef.current &&
      content?.data[lang]?.google_captcha_v2_public_key
    ) {
      reCaptchaRef.current.execute();
    } else {
      handleLogin();
    }
  };

  const handleClose = () => {
    if (matchCabinet && !isAuth) {
      navigate(`/${lang}/`);
    }
    setUseFiveDollarLogick(false);
    setLoginModal(false);
  };

  const isInvalidEmail = errors.email?.type === "pattern";
  const isLessThanSixChars =
    errors.password?.type === "validate" ||
    errors.rePassword?.type === "validate";

  const isContainSpaces =
    errors.password?.type === "pattern" ||
    errors.rePassword?.type === "pattern";

  return (
    <CustomModal
      open={isLoginModalOpened && !isAuth}
      onClose={handleClose}
      className="modal__login"
      header={modalCabinet.title}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="modal__login-form"
          noValidate={true}
        >
          <ModalInput
            label={formElem.email.label}
            // placeholder={formElem.email.placeholder}
            type="email"
            name="email"
            placeholder="email"
            watch={methods.watch}
            rules={{
              required: true,
              pattern: emailPattern
            }}
          />
          {isInvalidEmail && (
            <p className="modal__form-wrong modal__login-hint">
              {formElem.invalidEmail}
            </p>
          )}
          <ModalInput
            label={formElem.password.label}
            // placeholder={formElem.password.placeholder}
            type="password"
            name="password"
            placeholder="password"
            watch={methods.watch}
            rules={{
              validate: (value) => value?.length >= 6,
              pattern: passwordPattern
            }}
          />
          {isContainSpaces && (
            <p className="modal__form-wrong modal__login-hint">
              {profile?.wrongPasswordChars}
            </p>
          )}
          {isLessThanSixChars && (
            <p className="modal__form-wrong modal__login-hint">
              {profile?.wrongPasswordLength}
            </p>
          )}
          <div className="modal__login-row">
            <FormCheckBox
              name="remember"
              // defaultValue
              label={modalCabinet?.remember}
              // rules={{
              //   required: true
              // }}
            />
            <button
              type="button"
              className="modal__login-restore"
              onClick={() => {
                setRecoveryModal(true);
              }}
            >
              {modalCabinet.recoveryBtn}
            </button>
          </div>
          {isCaptchaEnabled &&
            content?.data[lang]?.google_captcha_v2_public_key && (
              <ReCAPTCHA
                ref={reCaptchaRef}
                // theme="dark"
                size="invisible"
                sitekey={content?.data[lang]?.google_captcha_v2_public_key}
                onChange={handleLogin}
              />
            )}
          <Button type="submit" disabled={disabled}>
            {isLoading ? <ButtonLoader /> : modalCabinet.enterBtn}
          </Button>
        </form>
      </FormProvider>
      <div className="modal__login-footer">
        <span> {modalCabinet.textSignUp}</span>
        <Button
          onClick={() => setRegisterModal(true)}
          className="modal__action"
        >
          {modalCabinet.signUpBtn}
        </Button>
      </div>
    </CustomModal>
  );
  // if (isLoginModalOpened && !isAuth) {
  //   return (
  //     <div id="owerlay" className="modal-owerlay" onClick={handleClose}>
  //       <div className="modal-container modal__login">
  //         <div className="modal__top">{modalCabinet.title}</div>
  //         <FormProvider {...methods}>
  //           <form
  //             onSubmit={methods.handleSubmit(onSubmit)}
  //             className="modal__login-form"
  //           >
  //             <ModalInput
  //               label={formElem.email.label}
  //               placeholder={formElem.email.placeholder}
  //               type="email"
  //               name="email"
  //             />
  //             <ModalInput
  //               label={formElem.password.label}
  //               placeholder={formElem.password.placeholder}
  //               type="password"
  //               name="password"
  //             />
  //             <div className="modal__login-row">
  //               <FormCheckBox
  //                 name="policy"
  //                 // defaultValue
  //                 label={modalCabinet?.remember}
  //                 rules={{
  //                   required: true,
  //                 }}
  //               />
  //               <span
  //                 className="modal__login-restore"
  //                 onClick={() => setRecoveryModal(true)}
  //               >
  //                 {modalCabinet.recoveryBtn}
  //               </span>
  //             </div>
  //             <Button type="submit" disabled={disabled}>
  //               {modalCabinet.enterBtn}
  //             </Button>
  //           </form>
  //         </FormProvider>
  //         <div className="modal__login-footer">
  //           <span> {modalCabinet.textSignUp}</span>
  //           <button
  //             onClick={() => setRegisterModal(true)}
  //             className="modal__action"
  //           >
  //             {modalCabinet.signUpBtn}
  //           </button>
  //         </div>

  //         <button
  //           className="modal-container-close_btn"
  //           onClick={() => {
  //             setUseFiveDollarLogick(false);
  //             setLoginModal(!isLoginModalOpened);
  //           }}
  //         >
  //           <IconSvg tag="close" />
  //         </button>
  //       </div>
  //     </div>
  //   );
  // } else {
  //   return null;
  // }
};
