import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import i18n from "i18next";
import { IconSvg } from "../../../utils/constants/icons/iconSvg.constant";

import "./Languages.scss";
import { classNames } from "../../../utils/helpers/classNames";

export const Languages = ({ menuDirection = "bottom" }) => {
  const { lang } = useParams();
  const [isOpen, setIsOpen] = useState(false);

  const localLanguages = i18n.languages || [];

  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguageHandler = (newLang) => {
    // eslint-disable-next-line import/no-named-as-default-member
    i18n.changeLanguage(newLang);

    if (window?.$zoho?.salesiq?.language) {
      window.$zoho.salesiq.language(newLang);
    }

    navigate({
      pathname: `/${newLang}/${location.pathname.slice(4)}`,
      hash: location.hash,
      search: location.search,
      state: location.state
    });

    setIsOpen(false);
  };

  const debounce = (f, delay) => {
    let timerId;

    return (value) => {
      if (!value) {
        timerId = setTimeout(() => {
          f(false);
        }, delay);

        return;
      }

      clearTimeout(timerId);
      f(true);
    };
  };

  const handleMenu = debounce(setIsOpen, 300);

  return (
    <div
      className="language"
      onTouchStart={() => handleMenu(!isOpen)}
      onMouseEnter={() => handleMenu(true)}
      onMouseLeave={() => handleMenu(false)}
    >
      <div className="language__select">
        <div className="language__title">
          {lang.toUpperCase()}
        </div>
        <IconSvg
          tag="triangleDown12"
          className={classNames(
            "language__dropdown-indicator",
            { "language__dropdown-indicator--active": isOpen }
          )}
        />
      </div>
      {
      isOpen && (
          <div className={`language__dropdown language__dropdown--direction-${menuDirection}`}>
            <ul className="language__list">
              {localLanguages.map((value) => (
                <li
                  className={classNames(
                    "language__item",
                    { "language__item--is-active": value === lang }
                  )}
                  key={value}
                  onClick={() => changeLanguageHandler(value)}
                >
                  {value.toUpperCase()}
                </li>
              ))}
            </ul>
          </div>
        )
      }
    </div>
  );
};
