import { createSlice } from "@reduxjs/toolkit";

import { countries } from "../../../utils/constants/countries.constant";
import { PROXY_TYPES } from "../../../utils/constants/defaultData.constant";

import {
  fetchAllCountries,
  fetchIsUserAuth,
  fetchRentPeriods,
  fetchSiteContent,
  fetchSiteProxyParams,
  fetchSiteSeo,
  fetchPaymentSystems
} from "./Content.thunks";

const ContentReducer = createSlice({
  name: "content",
  initialState: {
    content: {
      data: {},
      isLoaded: false,
      fetchError: null
    },
    countries: {
      data: [],
      isLoaded: false,
      fetchError: null
    },
    faq: {
      data: {},
      isLoaded: false
    },
    rentPeriods: { data: {}, isLoaded: false, fetchError: null },
    activeData: {
      proxy: {
        value: "",
        label: "",
        code: ""
      },
      currency: {
        id: "",
        value: "",
        label: ""
      },
      country: {
        id: "",
        code: ""
      }
    },
    paymentSystems: {
      data: {},
      isLoaded: false,
      fetchError: null
    },
    proxies: {
      enabled: [],
      data: {
        token: {
          isEnabled: false,
          token: ""
        },
        api: false,
        balance: false,
        ipv4: false,
        ipv6: false,
        mobile: false
      },
      fiveDollarLogick: {
        enabled: false,
        use: false
      },
      isLoaded: false,
      fetchError: null
    },
    seo: {
      data: {},
      isLoaded: true,
      fetchError: null
    },
    user: {
      isAuth: false,
      email: "",
      fetchAuthError: null,
      isAuthLoaded: false
    },
    isCaptchaEnabled: true,
    isNotificationsEnabled: false,
    paddleConfig: null,
    isScheduledPaymentEnabled: false
  },
  reducers: {
    setActiveCurrency(state, action) {
      state.activeData.currency = action.payload;
    },
    setActiveProxy(state, action) {
      state.activeData.proxy = action.payload;
    },
    setActiveCountry(state, action) {
      state.activeData.country = action.payload;
    },
    setUserAuthData(state, action) {
      state.user.isAuth = action.payload.isAuth;
      state.user.email = action.payload.email;
    },
    setCaptchaStatus(state, action) {
      state.isCaptchaEnabled = action.payload;
    },
    setUseFiveDollarLogick(state, action) {
      state.proxies.fiveDollarLogick.use = action.payload;
    },

    setSiteContentSSR(state, action) {
      state.content.data = {
        ...state.content.data,
        [action.payload.locale]: action.payload.data
      };
      state.activeData.currency = {
        id: action.payload.data.currencies[0]?.id,
        value: action.payload.data.currencies[0]?.code,
        label: action.payload.data.currencies[0]?.code
      };
      state.content.isLoaded = true;
    },
    
    setFaqByParams(state, action) {
      state.faq.data = action.payload;
      state.faq.isLoaded = true;
    },
    
    setSiteProxyParamsSSR(state, action) {
      state.proxies.data = action.payload;
      if (action.payload.ipv4) {
        state.proxies.enabled.push(PROXY_TYPES.ipv4);
      }
      if (action.payload.ipv6) {
        state.proxies.enabled.push(PROXY_TYPES.ipv6);
      }
      if (action.payload.isp) {
        state.proxies.enabled.push(PROXY_TYPES.isp);
      }
      if (action.payload.mobile) {
        state.proxies.enabled.push(PROXY_TYPES.mobile);
      }
      if (action.payload.balance_updated_version) {
        state.proxies.fiveDollarLogick.enabled = true;
      }
      state.isNotificationsEnabled = action.payload.web_notifications || false;
      state.proxies.isLoaded = true;
      state.isScheduledPaymentEnabled = action.payload.v2_autoextend_system;
      state.paddleConfig = action.payload.userConfig;
    },
    setAllCountriesSSR(state, action) {
      state.countries.data = action.payload.filter((item) =>
        countries.includes(item?.code)
      );
      state.countries.isLoaded = true;
    },
    setRentPeriodsSSR(state, action) {
      state.rentPeriods.data = action.payload;
      state.rentPeriods.isLoaded = true;
    },
    setSiteSeoSSR(state, action) {
      state.seo.data = {
        ...state.seo.data,
        [action.payload.localeCode]: {
          ...state.seo.data[action.payload.localeCode],
          [action.payload.countryId || action.payload.proxyType]:
            action.payload.data
        }
      };
      state.seo.isLoaded = true;
    },
    setPaymentSystemsSSR(state, action) {
      state.paymentSystems.data = action.payload;
      state.paymentSystems.isLoaded = true;
    }
  },
  extraReducers: {
    [fetchSiteContent.pending]: (state) => {
      state.content.fetchError = null;
      // state.content.isLoaded = false;
    },
    [fetchSiteContent.fulfilled]: (state, action) => {
      state.content.data = {
        ...state.content.data,
        [action.payload.locale]: action.payload.data
      };
      state.activeData.currency = {
        id: action.payload.data.currencies[0]?.id,
        value: action.payload.data.currencies[0]?.code,
        label: action.payload.data.currencies[0]?.code
      };
      state.content.isLoaded = true;
    },
    [fetchSiteContent.rejected]: (state, action) => {
      state.content.fetchError = action.payload.data;
      state.content.isLoaded = true;
    },
    [fetchSiteProxyParams.pending]: (state) => {
      state.proxies.fetchError = null;
      state.proxies.isLoaded = false;
    },
    [fetchSiteProxyParams.fulfilled]: (state, action) => {
      state.proxies.data = action.payload;
      state.paddleConfig = action.payload.userConfig;
      state.isScheduledPaymentEnabled = action.payload.v2_autoextend_system;

      if (action.payload.ipv4) {
        state.proxies.enabled.push(PROXY_TYPES.ipv4);
      }
      if (action.payload.ipv6) {
        state.proxies.enabled.push(PROXY_TYPES.ipv6);
      }
      if (action.payload.isp) {
        state.proxies.enabled.push(PROXY_TYPES.isp);
      }
      if (action.payload.mobile) {
        state.proxies.enabled.push(PROXY_TYPES.mobile);
      }
      if (action.payload.balance_updated_version) {
        state.proxies.fiveDollarLogick.enabled = true;
      }
      state.isNotificationsEnabled = action.payload.web_notifications || false;
      state.proxies.isLoaded = true;
    },
    [fetchSiteProxyParams.rejected]: (state, action) => {
      state.proxies.fetchError = action.payload;
      state.proxies.isLoaded = true;
    },

    [fetchIsUserAuth.pending]: (state) => {
      state.user.fetchAuthError = null;
      state.user.isAuthLoaded = false;
    },
    [fetchIsUserAuth.fulfilled]: (state, action) => {
      state.user.isAuth = action.payload;
      state.user.isAuthLoaded = true;
      state.user.email =
      action?.payload &&
      !!JSON.parse(localStorage.getItem("client_profile"))?.email
        ? JSON.parse(localStorage.getItem("client_profile"))?.email
        : "";
      if (!action.payload) {
        localStorage.removeItem("client_profile");
      }
    },
    [fetchIsUserAuth.rejected]: (state, action) => {
      state.user.fetchAuthError = action.payload;
      state.user.isAuthLoaded = true;
    },

    [fetchSiteSeo.pending]: (state) => {
      state.seo.fetchError = null;
      state.seo.isLoaded = false;
    },
    [fetchSiteSeo.fulfilled]: (state, action) => {
      state.seo.data = {
        ...state.seo.data,
        [action.payload.localeCode]: {
          ...state.seo.data[action.payload.localeCode],
          [action.payload.countryId || action.payload.proxyType]:
            action.payload.data
        }
      };
      state.seo.isLoaded = true;
    },
    [fetchSiteSeo.rejected]: (state, action) => {
      state.seo.fetchError = action.payload.data;
      state.seo.isLoaded = true;
    },

    [fetchAllCountries.pending]: (state) => {
      state.countries.fetchError = null;
      state.countries.isLoaded = false;
    },
    [fetchAllCountries.fulfilled]: (state, action) => {
      state.countries.data = action.payload.filter((item) =>
        countries.includes(item?.code)
      );
      state.countries.isLoaded = true;
    },
    [fetchAllCountries.rejected]: (state, action) => {
      state.countries.fetchError = action.payload.data;
      state.countries.isLoaded = true;
    },
    [fetchRentPeriods.pending]: (state) => {
      state.rentPeriods.fetchError = null;
      state.rentPeriods.isLoaded = false;
    },
    [fetchRentPeriods.fulfilled]: (state, action) => {
      state.rentPeriods.data = action.payload;
      state.rentPeriods.isLoaded = true;
    },
    [fetchRentPeriods.rejected]: (state, action) => {
      state.rentPeriods.fetchError = action.payload.data;
      state.rentPeriods.isLoaded = true;
    },
    [fetchPaymentSystems.pending]: (state) => {
      state.paymentSystems.fetchError = null;
      state.paymentSystems.isLoaded = false;
    },
    [fetchPaymentSystems.fulfilled]: (state, action) => {
      state.paymentSystems.data = action.payload;
      state.paymentSystems.isLoaded = true;
    },
    [fetchPaymentSystems.rejected]: (state, action) => {
      state.paymentSystems.fetchError = action.payload.data;
      state.paymentSystems.isLoaded = true;
    }
  }
});

export const ContentReducerActions = {
  ...ContentReducer.actions,
  fetchSiteContent,
  fetchSiteProxyParams,
  fetchIsUserAuth,
  fetchSiteSeo,
  fetchAllCountries,
  fetchRentPeriods,
  fetchPaymentSystems
};
export default ContentReducer.reducer;
