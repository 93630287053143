import "./AutoExtendingModal.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CheckBox } from "../../../../../../../../components/ui/CheckBoxes/CheckBox";
import { useDispatchedActions, useTranslation } from "../../../../../../../../hooks";
import { IconSvg } from "../../../../../../../../utils/constants/icons/iconSvg.constant";
import { useSetZIndex } from "../../../../../../../../hooks/useSetZIndex";
import { Button } from "../../../../../../../../components/ui/Buttons/Button/Button";
import { getPaymentProviderIcon } from "../../../../../../../../utils/helpers/getPaymentProviderIcon";
import { classNames } from "../../../../../../../../utils/helpers/classNames";
import { ButtonLoader } from "../../../../../../../../components/ui/ButtonLoader/ButtonLoader";
import { errorToast } from "../../../../../../../../utils/helpers/customToast.helper";
import { ApiService } from "../../../../../../../../services/ApiService";

export const AutoExtendingModal = ({ rentPeriods, card, isLoading, handleConfirm, paymentTypes, userBalance }) => {
  useSetZIndex(".cabinet", "999");

  const { lang } = useParams();

  const { ordersForUpdate, selectedRows } = useSelector((state) => state.cabinet);
  const { activeData } = useSelector((state) => state.content);

  const [isConfirmation, setIsConfirmation] = useState(false);
  const [selectedRentPeriod, setSelectedRentPeriod] = useState(rentPeriods[0]);
  const [paymentType, setPaymentType] = useState(paymentTypes.balance);
  const [price, setPrice] = useState(null);

  const {
    creditCard: { card: cardLabel, paypal: paypalLabel },
    buttons: { confirm },
    cabinet: { orders: { autoExtending: { modals: { confirm: {
      autoRenewal,
      selectedProxy,
      pcs,
      cancelButton,
      autoRenewalButton,
      description,
      paymentMethod,
      balance,
      totalPrice,
      confirmationHintPart1,
      confirmationHintPart2
    } } } } }
  } = useTranslation();

  const { setIsExtendingModalOpen, setOrdersForUpdate, setOrderData } = useDispatchedActions();

  const showConfirmationModal = () => {
    setIsConfirmation(true);
  };

  const confirmAction = () => {
    if (isConfirmation) {
      handleConfirm(selectedRentPeriod?.days, paymentType);

      return;
    }

    showConfirmationModal();
  };

  const cancelHandler = () => {
    setOrderData({});
    setOrdersForUpdate([]);
    setIsExtendingModalOpen(false);
  };

  const getPaymentMethodText = () => {
    const labels = {
      en: {
        [paymentTypes.paddle]: "your card",
        [paymentTypes.balance]: "your balance"
      },
      ru: {
        [paymentTypes.paddle]: "вашей карты",
        [paymentTypes.balance]: "вашего баланса"
      }
    };

    return labels[lang][paymentType];
  };

  useEffect(() => {
    (async () => {
      try {
        const { data, status } = await ApiService.getAutoExtendPrice({
          rentPeriodId: selectedRentPeriod?.id,
          paymentType,
          ipsId: ordersForUpdate.length ? ordersForUpdate : selectedRows,
          proxyType: activeData?.proxy?.code
        });

        if (status !== 200) {
          throw new Error();
        }

        setPrice(data?.amount);
      } catch (e) {
        errorToast("Something went wrong");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRentPeriod?.id, paymentType, activeData?.proxy?.code]);

  return (
    <div className="confirm-extending">
      {!isConfirmation ? (
        <>
          <div className="confirm-extending__title">{autoRenewal}</div>
          <div className="confirm-extending__description">{description}</div>
          <div className="confirm-extending__days">
            <div className="confirm-extending__label">Срок:</div>
            <div className="confirm-extending__days-container">
              {rentPeriods.map((rentPeriod) => (
                <CheckBox
                  key={rentPeriod.id}
                  label={rentPeriod.label}
                  checked={rentPeriod.id === selectedRentPeriod?.id}
                  onChange={() => setSelectedRentPeriod(rentPeriod)}
                />
              ))}
            </div>
          </div>
          <div className="confirm-extending__payment-method">
            <span className="confirm-extending__label">{paymentMethod}</span>
            <div className={classNames(
              "confirm-extending__payment-method-container",
              { "confirm-extending__payment-method-container--full": !card }
            )}>
              {card && (
                <div
                  className={classNames(
                    "confirm-extending__credit-card",
                    { "confirm-extending__payment-method--active": paymentType === paymentTypes.paddle }
                  )}
                  onClick={() => setPaymentType(paymentTypes.paddle)}
                >
                  {card.cardType === "paypal" ? (
                      <div className="confirm-extending__paypal">
                        <div className="confirm-extending__paypal-icon">
                          <div className="confirm-extending__card-icon-bg">
                            <IconSvg tag={getPaymentProviderIcon(card.cardType)} />
                          </div>
                        </div>
                        <div className="confirm-extending__paypal-title">{paypalLabel}</div>
                      </div>
                  ) : (
                    <>
                      <div className="confirm-extending__heading">{cardLabel}</div>
                      <div className="confirm-extending__card-details">
                        <div className="confirm-extending__card-icon">
                          <div className="confirm-extending__card-icon-bg">
                            <IconSvg tag={getPaymentProviderIcon(card.cardType)} />
                          </div>
                        </div>
                        <div className="confirm-extending__card-num">**** **** {card.lastFourDigits}</div>
                        <div className="confirm-extending__card-date">EXP {card.expiryDate}</div>
                      </div>
                    </>
                  )}
                </div>
              )}
              <div
                className={classNames(
                  "confirm-extending__balance",
                  { "confirm-extending__payment-method--active": paymentType === paymentTypes.balance }
                )}
                onClick={() => setPaymentType(paymentTypes.balance)}
              >
                <div className="confirm-extending__heading">{balance}</div>
                <div className="confirm-extending__balance-num">{userBalance ? `$${userBalance}` : "-"}</div>
              </div>
            </div>
          </div>
          <div className="confirm-extending__proxies-quantity">
            {selectedProxy} <span>{ordersForUpdate.length || selectedRows.length} {pcs}.</span>
          </div>
          <div className="confirm-extending__proxies-price">
            {totalPrice} <span>{price ? `$${price}` : "-"}</span>
          </div>
        </>
      ) : (
        <div className="confirm-extending__confirmation">
          <IconSvg tag="confirmIcon" />
          <div className="confirm-extending__hint">
            {`${confirmationHintPart1} ${selectedRentPeriod?.value} ${confirmationHintPart2} ${getPaymentMethodText()}`}
          </div>
        </div>
      )}
      <div className="confirm-extending__actions">
        <Button
          onClick={cancelHandler}
        >
          {cancelButton}
        </Button>
        <Button
          tab="confirm"
          onClick={confirmAction}
          disabled={isLoading}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading
            ? <ButtonLoader />
            : isConfirmation ? confirm : autoRenewalButton}
        </Button>
      </div>
    </div>
  );
};
