import { Helmet } from "react-helmet";

import { matchPath, matchRoutes, useLocation, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { getRobotContent, isSSR } from "../../../utils/helpers";
import { AppRoutes, CabinetRoutes, OtherRoutes } from "../../../Router/routes";
import { useCurrentSeo, useTranslation } from "../../../hooks";
import { PROXY_TYPES } from "../../../utils/constants";

export const MetaDate = () => {
    const { pathname } = useLocation();
    const { lang } = useParams();
    const seo = useCurrentSeo();
    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab");

    const {
        cabinet: {
            documentation: { descriptionsAPI },
            profile,
            orders,
            balance,
            documentation,
            notifications,
            newOrder,
            faq
        }
    } = useTranslation();
    const { content, activeData, countries, faq: faqData } = useSelector((state) => state.content);

    const currentCabinetMeta = () => {
        const cabinetTitles = {
            profile: profile?.title || "",
            balance: balance?.title,
            orders: orders?.title,
            "new-order": newOrder?.tabName,
            documentation: documentation?.title,
            notifications: notifications?.tabName,
            faq: faq?.tabName
        };
        return cabinetTitles[tab] ? cabinetTitles[tab] : cabinetTitles.profile;
    };

    const metas = {
        seo: {
            title: seo?.metaTitle || "",
            description: seo?.metaDescription || ""
        },
        metaDateReviews: {
            title: content?.data?.[lang]?.metaDateReviews?.title || "",
            description: content?.data?.[lang]?.metaDateReviews?.description || ""
        },
        metaDateFeedback: {
            title: content?.data?.[lang]?.metaDateFeedback?.title || "",
            description: content?.data?.[lang]?.metaDateFeedback?.description || ""
        },
        metaDateDocumentation: {
            title: descriptionsAPI?.title || "",
            description: descriptionsAPI?.info
                ? descriptionsAPI.info.replace("#", "")
                : ""
        },
        dashboard: { title: isSSR() ? "Dashboard" : currentCabinetMeta(), description: "Dashboard" },
        default: { title: "IPv4", description: "Best IPv4 proxy" }
    };

    const setMetaDate = () => {
        const currentRoute = matchRoutes(
            [...OtherRoutes, ...CabinetRoutes, ...AppRoutes],
            pathname
        );
        return currentRoute[0].route.metaData;
    };

    const meta = metas?.[setMetaDate()] || metas?.default;

    const homeURL = process.env.REACT_APP_HOME_URL;

  // const filteredFaq = useMemo(() => {
  //   if (activeData?.proxy?.code === PROXY_TYPES.ipv4.code && !activeData?.country?.id) {
  //     return content?.data[lang]?.faq.filter((item) => item?.mainPage);
  //   }
  //
  //   return content?.data[lang]?.faq
  //     .filter((item) => item?.proxyTypes?.includes(activeData?.proxy?.code));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [activeData?.proxy?.code, activeData?.country?.id, lang, content?.data[lang]?.faq]);

  const getFAQList = () => {
    const faq = faqData?.data?.[lang];

    if (faq) {
      return faq.map((item) => (
        `{
        "@type": "Question",
        "name": "${item.question}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "${item.answer.replace(/"/g, "'")}"
        }
      }`
      ));
    }
  };

  const isFAQListVisible = () => {
    const faqNotEnablePaths = [
      "/payment/:status/",
      "/:lang/documentation-api/",
      "/:lang/dashboard/",
      "/:lang/payment/replenishment/",
      "/:lang/api/recovery/:hash",
      "/:lang/payment/:status/",
      "/:lang/feedback/",
      "/:lang/contacts/"
    ];

    for (const notEnablePath of faqNotEnablePaths) {
      if (matchPath(notEnablePath, pathname)) {
        return false;
      }
    }

    return true;
  };

  const getPriceByType = (proxyType, country) => {
    switch (proxyType) {
      case PROXY_TYPES.ipv4.code:
        return country?.minIPv4Price;
      case PROXY_TYPES.ipv6.code:
        return country?.minIPv6Price;
      case PROXY_TYPES.isp.code:
        return country?.minISPPrice;
      default:
        return null;
    }
  };

  const getCountryPrice = () => {
    const proxyType = activeData?.proxy?.code;
    const countryId = activeData?.country?.id;

    if (!proxyType || !countryId || !countries?.data?.length) {
      return;
    }

    const selectedCountry = countries?.data?.find((country) => country?.id === countryId);

    return getPriceByType(proxyType, selectedCountry);
  };

  const isPriceMarkupVisible = getCountryPrice();

  const icons = [
    {
      id: "favicon",
      rel: "shortcut icon",
      icons: {
        dark: `${homeURL}/favicon-dark.ico`,
        light: `${homeURL}/favicon-light.ico`
      },
      type: "image/x-icon",
      media: {
        dark: "(prefers-color-scheme: dark)",
        light: "(prefers-color-scheme: light)"
      }
    },
    {
      id: "appleIcon",
      rel: "apple-touch-icon",
      sizes: "180x180",
      icons: {
        dark: `${homeURL}/apple-touch-icon-dark.png`,
        light: `${homeURL}/apple-touch-icon-light.png`
      },
      media: {
        dark: "(prefers-color-scheme: dark)",
        light: "(prefers-color-scheme: light)"
      }
    },
    {
      id: "favicon32",
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      icons: {
        dark: `${homeURL}/favicon-32x32-dark.png`,
        light: `${homeURL}/favicon-32x32-light.png`
      },
      media: {
        dark: "(prefers-color-scheme: dark)",
        light: "(prefers-color-scheme: light)"
      }
    },
    {
      id: "favicon16",
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      icons: {
        dark: `${homeURL}/favicon-16x16-dark.png`,
        light: `${homeURL}/favicon-16x16-light.png`
      },
      media: {
        dark: "(prefers-color-scheme: dark)",
        light: "(prefers-color-scheme: light)"
      }
    },
    {
      id: "safariIcon",
      rel: "mask-icon",
      icons: {
        dark: `${homeURL}/safari-pinned-tab-dark.svg`,
        light: `${homeURL}/safari-pinned-tab-light.svg`
      },
      color: "#5bbad5",
      media: {
        dark: "(prefers-color-scheme: dark)",
        light: "(prefers-color-scheme: light)"
      }
    }
  ];

  // range 4.5 - 4.9

  // eslint-disable-next-line arrow-body-style
  const randomRating = useMemo(() => {
    return (Math.random() * 0.4 + 4.5).toFixed(1);
  }, []);

  const reviewsCountByDate = useMemo(() => {
    let initReviewsCount = 104;

    const initTimestamp = new Date("2024-08-19T00:00:00Z").getTime();
    const currentTimestamp = Date.now();
    const millisecondsIn30Days = 30 * 24 * 60 * 60 * 1000;

    const monthsPassed = Math.floor((currentTimestamp - initTimestamp) / millisecondsIn30Days);

    if (monthsPassed <= 0) return initReviewsCount;

    initReviewsCount *= 1.03 ** monthsPassed;

    return Math.round(initReviewsCount);
  }, []);

  const nextYearValue = useMemo(() => {
    const today = new Date();

    const nextYear = new Date(today);
    nextYear.setFullYear(today.getFullYear() + 1);

    return nextYear.toISOString().split("T")[0];
  }, []);

  return (
        <Helmet>
            <meta charset="utf-8" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <title>{meta?.title}</title>
            <meta name="description" content={meta?.description} />
            <meta itemProp="name" content={meta?.title} />
            <meta itemProp="description" content={meta?.description} />
            <meta name="robots" content={getRobotContent()} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta?.title} />
            <meta property="og:description" content={meta?.description} />
            <meta name="twitter:title" content={meta?.title} />
            <meta name="twitter:description" content={meta?.description} />
            {icons.map((iconData) => (
              <link
                key={iconData.id}
                id={iconData.id}
                rel={iconData.rel}
                href={iconData.icons.light}
                media={iconData.media.light}
                type={iconData?.type || ""}
                sizes={iconData?.sizes || ""}
                color={iconData?.color || ""}
              />
            ))}
            {icons.map((iconData) => (
              <link
                key={iconData.id}
                id={iconData.id}
                rel={iconData.rel}
                href={iconData.icons.dark}
                media={iconData.media.dark}
                type={iconData?.type || ""}
                sizes={iconData?.sizes || ""}
                color={iconData?.color || ""}
              />
            ))}
            <link
                rel="manifest"
                href={`${homeURL}/manifest.json`}
            />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="customTags" content="#customHeaderTagsFromDB" />

            <link
                rel="alternate"
                hrefLang="x-default"
                href={homeURL}
            />
            <link
                rel="alternate"
                hrefLang="en"
                href={`${homeURL}/en/`}
            />
            <link
                rel="alternate"
                hrefLang="ru"
                href={`${homeURL}/ru/`}
            />
          {isFAQListVisible() && (
            <script type="application/ld+json">
              {`
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [${getFAQList()}]
              }
            `}
            </script>
          )}
          {isPriceMarkupVisible && (
            <script type="application/ld+json">
              {`
              {
                "@context":"http://schema.org",
                "@type":"Product",
                "image": [
                  "${homeURL}/img/ui/new_logo-light_v2.svg"
                ],
                "name": "Proxy Server",
                "description": "Discover reliable proxy server lists. Enjoy anonymity with our HTTPS and SOCKS5 proxies. Experience fast, private proxies. Boost your business performance with Proxy IPv4.",
                "brand": {
                  "@type": "Brand",
                  "name": "Proxy IPv4"
                },
                "sku": "0000000008",
                "mpn": "000008",
                "aggregateRating":{
                  "@type":"AggregateRating",
                  "ratingValue": ${randomRating},
                  "reviewCount": ${reviewsCountByDate},
                  "bestRating": 5
                },
                "review": {
                  "@type": "Review",
                  "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": ${randomRating},
                    "bestRating": "5"
                  },
                  "author": {
                    "@type": "Person",
                    "name": "Mike"
                  }
                },
                "offers": {
                  "@type": "Offer",
                  "url": "${homeURL}${pathname}",
                  "priceCurrency": "USD",
                  "price": "${getCountryPrice()}",
                  "priceValidUntil": "${nextYearValue}",
                  "availability": "https://schema.org/InStock",
                  "itemCondition": "https://schema.org/NewCondition"
                }
              }
            `}
            </script>
          )}
        </Helmet>
    );
};
