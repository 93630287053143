import { useMatch } from "react-router-dom";
import { MobileProxyReducerActions } from "../../redux/reducers/MobileProxyReducer/MobileProxy.reducer";
import { ContentReducerActions } from "../../redux/reducers/ContentReducer/Content.reducer";
import { HeaderReducerActions } from "../../redux/reducers/HeaderReducer/Header.reducer";
import { ApiService } from "../../services/ApiService";
import { Seo } from "./components/Seo/Seo";
import { FaqSection } from "./components/FaqSection/FaqSection";
import { Feedback } from "./components/Feedback/Feedback";
import { Index } from "./components/Index/Index";
import { Prices } from "./components/Prices/Prices";
import { Tips } from "./components/Tips/Tips";
import { ProxyManadger } from "./components/ProxyManadger/ProxyManadger";
import { OurClients } from "./components/OurClients/OurClients";
import { Benefits } from "./components/Benefits/Benefits";

const MainPage = () => {
  const matchIPv4 = useMatch("/:lang");

  return (
    <>
      <Index />
      <Tips />
      <Prices />
      {matchIPv4 ? <Benefits /> : <ProxyManadger />}
      <Feedback />
      <OurClients />
      <FaqSection />
      <Seo />
    </>
  );
};

MainPage.getServerSideState = async (store, params, baseURL) => {
  const [
    content,
    proxyParams,
    countries,
    rentPeriod
    // faqByParams
  ] = await Promise.all([
    ApiService.getFullContentSSR(baseURL, params.locale),
    ApiService.getFrontConfigSSR(baseURL, params.userIp),
    ApiService.getAllCountriesSSR(baseURL, params.userIp),
    ApiService.getRentPeriodsSSR(baseURL)
    // ApiService.getFaqByParamsSSR(baseURL, params.proxy, params.country || params.countryFromQuery)
  ]);

  let faqByParams = {};

  const countryUrl = params.country || params.countryFromQuery;
  const isSuchCountryExist = countries?.data && countries?.data?.find(({ url }) => url === countryUrl);

  if (isSuchCountryExist || !countryUrl) {
    faqByParams = await ApiService.getFaqByParamsSSR(baseURL, params.proxy, params.country || params.countryFromQuery);
  }

  store.dispatch(ContentReducerActions.setFaqByParams(faqByParams.data || {}));
  store.dispatch(HeaderReducerActions.setDarckTheme(params.theme));
  store.dispatch(
    ContentReducerActions.setSiteContentSSR({
      data: content.data,
      locale: params.locale
    })
  );

  store.dispatch(ContentReducerActions.setSiteProxyParamsSSR(proxyParams.data));

  const tempTypeProxy = store
    .getState()
    .content.proxies?.enabled.find((item) => item?.code === params.proxy);

  if (tempTypeProxy?.code) {
    store.dispatch(ContentReducerActions.setActiveProxy(tempTypeProxy));
  }

  const currencyId = store.getState().content?.activeData?.currency?.id;
  if (currencyId) {
    const mobileTariff = await ApiService.getMobileProxySSR(baseURL, currencyId);
    store.dispatch(
      MobileProxyReducerActions.setMobileTariffsSSR({
        data: mobileTariff.data,
        currencyId
      })
    );
  }

  const country =
    countries.data.find((el) => el.url === params.country) ||
    // countries.data.find((item) =>
    //   item.showOnFrontType.includes(params.proxy)
    // ) ||
    {};

  const countryId = country?.id || "";
  const countryCode = country?.code || "";

  store.dispatch(
    ContentReducerActions.setActiveCountry({
      id: params.proxy !== "MOBILE" ? countryId : "",
      code: params.proxy !== "MOBILE" ? countryCode : ""
    })
  );

  const finalSeoInfo = await ApiService.getSeoInfoSSR(baseURL, {
    countryId: country?.id || "",
    localeCode: params.locale,
    proxyType: params.proxy
  });

  store.dispatch(ContentReducerActions.setUserAuthData({ isAuth: false, email: "" }));
  store.dispatch(ContentReducerActions.setAllCountriesSSR(countries.data));
  store.dispatch(ContentReducerActions.setRentPeriodsSSR(rentPeriod.data));

  store.dispatch(
    ContentReducerActions.setSiteSeoSSR({
      data: finalSeoInfo.data,
      countryId: country?.id || "",
      localeCode: params.locale,
      proxyType: params.proxy
    })
  );
};

export default MainPage;
