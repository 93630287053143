/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import { load } from "@fingerprintjs/fingerprintjs";
import { useSelector } from "react-redux";

import {useLocation} from "react-router-dom";
import { ContentDataErrorMesege } from "./components/common/ContentDataErrorMesege/ContentDataErrorMesege";
import { Router } from "./Router/Router";

import { ApiService } from "./services/ApiService";

import { useDetectDefaultLang } from "./hooks/useDetectDefaultLang";
import { useDispatchedActions } from "./hooks/useDispatchedActions";
import { genString } from "./utils/helpers";
import { setClientToken } from "./utils/helpers/setClientToken";
import i18n from "./i18n/config";

import 'swiper/css';

export const App = () => {
  const { pathname } = useLocation();
  const {
    fetchSiteContent,
    fetchSiteProxyParams,
    fetchMobileProxyTariff,
    fetchIsUserAuth,
    setCaptchaStatus,
    fetchAllCountries,
    fetchRentPeriods,
    fetchPaymentSystems
  } = useDispatchedActions();

  // **Redux State
  const {
    countries,
    content,
    activeData,
    // isCaptchaEnabled,
    proxies,
    rentPeriods,
    paymentSystems,
    user
  } = useSelector((state) => state.content);
  const { mobileTariffs } = useSelector((state) => state.mobileProxy);
  const [fingerprintLoaded, setFingerprintLoaded] = useState(false);

  const getFingerprint = async () => {
    try {
      const fp = await load();
      const result = await fp.get();
      if (result?.visitorId) {
        sessionStorage.setItem("fgpJS", result.visitorId);
      }
    } finally {
      setFingerprintLoaded(true);
    }
  };
  // // some shit!
  // const { pathname } = useLocation();
  // let locale = "en"
  // if (pathname.includes("/ru")) {
  //   locale = "ru"
  // }
  // if (pathname.includes("/en")) {
  //   locale = "en"
  // }
  // // *Hooks
  // const lang = (locale)?locale:useDetectDefaultLang();
  const lang = useDetectDefaultLang();
  // *State
  const [checkAllLoaded, setCheckAllLoaded] = useState("load");

  const downloadRestLocales = (loadedLocales) => {
    const enabledLocales = ["en", "ru"];

    const restLocales = enabledLocales.filter(
      (locale) => !loadedLocales.includes(locale)
    );
    restLocales.forEach((locale) => fetchSiteContent(locale));
  };

  useEffect(() => {
    setClientToken();
    // Initial Fetching fingerprint
    if (!sessionStorage.reactJSId) {
      sessionStorage.reactJSId = `s${genString(
        15
      )}${new Date().getTime()}${genString(2)}`;
    }
    getFingerprint();
  }, []);

  // Initial Fetching
  useEffect(() => {
    if (fingerprintLoaded) {
      // Initial Fetching content for site
      if (!content?.isLoaded) {
        fetchSiteContent(lang);
        downloadRestLocales([lang]);
      } else {
        const loadedLocales = Object.keys(content?.data);
        downloadRestLocales(loadedLocales);
      }

      // Initial Fetching user auth
      fetchIsUserAuth();

      // Initial Fetching proxy enabled
      if (!proxies?.isLoaded) {
        fetchSiteProxyParams();
      }

      // Initial Fetching default countries
      if (!countries?.isLoaded) {
        fetchAllCountries();
      }

      // Initial Fetching default rent periods
      if (!rentPeriods?.isLoaded) {
        fetchRentPeriods();
      }

      // Checking for token status
      ApiService.tokenStatus().then((req) => {
        if (req?.data) {
          setCaptchaStatus(req.data);
        } else {
          setCaptchaStatus(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fingerprintLoaded]);

  useEffect(() => {
    if (
      content?.isLoaded &&
      proxies?.isLoaded &&
      countries?.isLoaded &&
      rentPeriods?.isLoaded &&
      fingerprintLoaded
    ) {
      if (
        content?.fetchError ||
        proxies?.fetchError ||
        countries?.fetchError ||
        rentPeriods?.fetchError
      ) {
        setCheckAllLoaded("error");
      } else {
        setCheckAllLoaded("success");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content?.isLoaded,
    proxies?.isLoaded,
    countries?.isLoaded,
    rentPeriods?.isLoaded,
    fingerprintLoaded
  ]);

  // Initial fetching payment systems
  useEffect(() => {
    if (checkAllLoaded === "success") {
      const keysMobileProxyTariff = Object.keys(mobileTariffs);
      if (!keysMobileProxyTariff.includes(activeData?.currency?.id)) {
        fetchMobileProxyTariff(activeData?.currency?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeData?.currency, checkAllLoaded]);

  useEffect(() => {
    if (user?.isAuthLoaded && fingerprintLoaded) {
      if (
        !paymentSystems?.isLoaded ||
        paymentSystems?.data?.email !== user?.email
      ) {
        fetchPaymentSystems({ email: user?.email });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.isAuthLoaded, fingerprintLoaded, user?.email, pathname]);
  
  // if (isSSR()) {
  //   return <Router />;
  // }
  // } else if (!isSSR()) {
  // if (checkAllLoaded === "load") {
  //   // return <Loading fixed mainLoader />;
  //   return <></>;
  // }
  
  // return <div>
  //   {rentPeriods?.isLoaded.toString()}
  // </div>
  
  if (checkAllLoaded === "error") {
    return <ContentDataErrorMesege />;
  }
  return <Router />;
  //   return isCaptchaEnabled &&
  //     content?.data[lang]?.google_captcha_public_key ? (
  //     <GoogleReCaptchaProvider
  //       reCaptchaKey={content?.data[lang]?.google_captcha_public_key}
  //     >
  //       <Router />
  //     </GoogleReCaptchaProvider>
  //   ) : (
  //     <>
  //       <Router />
  //     </>
  //   );
  // }
};
