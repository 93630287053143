/* eslint-disable camelcase */
import { useEffect, useRef, useState } from "react";

import { Address4 } from "ip-address";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";
import { getG4Data } from "../../../../../utils/helpers";
import { ApiService } from "../../../../../services/ApiService";
import { protocols } from "../../../../../utils/constants/protocols.constant";

import { getCountries } from "../../../../../utils/helpers/getCountries.helper";

import { Loading } from "../../../../../components/ui/Loading/Loading";
import { ModalInput } from "../../../../../components/ui/ModalInput/ModalInput";
import { ModalSelect } from "../../../../../components/ui/ModalSelect/ModalSelect";

import { useCurrentRentPeriod } from "../../../../../hooks/useCurrentRentPeriod";
import { useDebounceValue } from "../../../../../hooks/useDebounceValue";
import { useDispatchedActions } from "../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../hooks/useTranslation";

import "./OrderForm.scss";
import { Button } from "../../../../../components/ui/Buttons";
import {
  errorToast,
  warningToast
} from "../../../../../utils/helpers/customToast.helper";
import { AuthParams } from "../../../../../components/common/ModalCustom/components/Form/components/AuthParams";
import { ClientEmail } from "../../../../../components/common/ModalCustom/components/Form/components/ClientEmail";
import { PaymentSystem } from "../../../../../components/common/ModalCustom/components/Form/components/PaymentSystem";
import { AgreeWithRules } from "../../../../../components/common/agreeWithRules/AgreeWithRules";
import { isValidQuantity } from "../../../../../utils/helpers/isValidQuantity";
import { getQuantityWarningMessage } from "../../../../../utils/helpers/getQuantityWarningMessage";
import { checkIpAgainstRanges } from "../../../../../utils/validation/ipValidation";
import { Countries } from "./Countries";
import { MobileoperatorParams } from "./MobileoperatorParams";
export const Form = (props) => {
  // **Props
  const { proxyCode, controller, isFormSubmitting, setIsFormSubmitting } =
    props;
  //   console.log(proxyCode)
  const { lang } = useParams();
  const reCaptchaRef = useRef(null);
  // const { executeRecaptcha } = useGoogleReCaptcha();

  // **Redux State
  const { modal } = useSelector((state) => state);

  const { projects_form_crm } = useSelector(
    (state) => state.content.proxies.data
  );
  const {
    countries,
    content,
    isCaptchaEnabled,
    activeData,
    user,
    proxies: { fiveDollarLogick }
  } = useSelector((state) => state.content);

  // **Dispatch
  const {
    clearPrices,
    // setModalOpen,
    setRentPeriod,
    // setCountryModal,
    fetchPriceCalculationModal,
    setParamsForReload,
    // setIsBalanceCheck,
    resetParamsForReload
    // ,setUseFiveDollarLogick,
    // setModalNotAuthBalance,
    // setFooterModal
  } = useDispatchedActions();

  // **State
  const [options, setOptions] = useState([]);
  // const [displayPrices, setDisplayPrices] = useState("-");
  const [proxyProtocol, setProxyProtocol] = useState(null);
  const [countryParams, setCountryParams] = useState({
    id: "",
    code: ""
  });
  const [mobileOperators, setMobileOperators] = useState([]);
  const [mobileOperator, setMobileOperator] = useState(null);
  const [modemRotationMin, setModemRotationMin] = useState({});

  const [isAgreeWithConditions, setIsAgreeWithConditions] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [europeCountries, setEuropeCountries] = useState([]);
  const [quantity, setQuantity] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [useReCaptchaV3, setUseReCaptchaV3] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [tokenV2, setTokenV2] = useState(null);
  const [userBalance, setUserBalance] = useState({
    balance: 0,
    currencyCode: "",
    success: false
  });

  // Form
  const methods = useForm({
    mode: "onSubmit"
  });
  const { rent, asyncGoals, payment } = methods.watch();

  // **Hooks
  const {
    formPlaceholders,
    orderForm,
    notifications,
    prices,
    main: {
      agreeWithRules: { warningMessage }
    }
  } = useTranslation();
  const debouncedQuantity = useDebounceValue(quantity, 500);
  const rentPeriods = useCurrentRentPeriod(proxyCode);

  useEffect(() => {
    if (isValidQuantity(proxyCode, quantity)) {
      methods.clearErrors("quantity");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxyCode]);

  // Ref
  const goalsSelect = useRef(null);

  const analitics = () => {
    let orderLabel = "";
    switch (proxyCode) {
      case "IPv4":
        orderLabel = "order";
        break;
      case "IPv6":
        orderLabel = "order_ipv6";
        break;
      case "MOBILE":
        orderLabel = "order_mobile";
        break;
      default:
        orderLabel = "order";
        break;
    }
    // google analitika
    if (
      window.location.hostname !== "localhost" &&
      typeof window.gtag !== "undefined"
    ) {
      // eslint-disable-next-line no-undef
      window.gtag("event", "Click", {
        // eslint-disable-next-line camelcase
        event_category: "Pay_Button",
        // eslint-disable-next-line camelcase
        event_label: orderLabel
      });
    }

    // yandex analitika
    if (
      window.location.hostname !== "localhost" &&
      typeof window.ym !== "undefined"
    ) {
      // eslint-disable-next-line no-undef
      ym(87392270, "reachGoal", orderLabel);
    }
  };

  const combineParams = (data, token) => {
    let projectId = "";
    let ownSubgoal = "";
    // logik use iv4/crm goals
    if (projects_form_crm) {
      ownSubgoal = data?.asyncGoals?.value.trim();
    } else {
      projectId =
        data?.asyncGoals?.label.trim() !== data?.asyncGoals?.value.trim()
          ? data?.asyncGoals?.value.trim()
          : "";
      ownSubgoal =
        data?.asyncGoals?.label.trim() === data?.asyncGoals?.value.trim()
          ? data?.asyncGoals?.value.trim()
          : "";
      const equalValue = options?.filter(
        ({ label }) =>
          label.toLowerCase() === data?.asyncGoals?.value.toLowerCase().trim()
      );

      if (equalValue.length > 0) {
        projectId = equalValue[0]?.value;
        ownSubgoal = "";
      }
    }

    if (data?.authIp && !Address4.isValid(data?.authIp)) {
      throw notifications?.wrongIp;
    }
    return {
      paymentSystemId: data?.payment?.id,
      currency: modal?.prices?.currencyCode,
      countryId: countryParams?.id,
      count: +data?.quantity,
      days: data?.rent?.days,
      projectId,
      authType: data?.auth?.value,
      locale: lang,
      projectCustom: ownSubgoal || "",
      price: modal?.prices?.price,
      captchaToken: token ? token : "",
      amount: modal?.prices?.amount || 0,
      clientEmail: data.mail || "",
      authIp: data.authIp || "",
      proxyType: proxyCode,
      proxyProtocol,
      modemRotationMin: modemRotationMin?.min,
      mobileOperatorTag: mobileOperator?.value,
      ga4Info: getG4Data()
    };
  };

  const createOrder = async (tokenV2) => {
    const data = methods.getValues();
    try {
      const params = combineParams(data, tokenV2, true);
      const response = await ApiService.createPayment(params);
      if (response && (response?.status < 200 || response?.status >= 300)) {
        if (response?.data === "incorrect ip") {
          throw notifications?.wrongIp;
        }
        throw notifications.error;
      }

      if (response && response?.status === 202) {
        throw notifications?.errorCaptcha;
      }
      if (response && response?.status === 205) {
        setParamsForReload(data);
        throw notifications?.errorUserPaymentSystem;
      }
      if (response && response?.status === 226) {
        throw notifications.registerErrorWrongEmail;
      }

      if (response && response?.status === 200) {
        if (response?.data && response?.status === 200) {
          analitics();
          window.location.href = response.data;
        } else {
          throw notifications.error;
        }
      } else {
        throw notifications.error;
      }
    } catch (err) {
      errorToast(err?.message || err, lang);
      setIsFormSubmitting(false);
      if (reCaptchaRef.current) {
        reCaptchaRef.current?.reset();
      }
    }
  };

  // Form submit
  const onSubmit = (data) => {
    if (!isAgreeWithConditions) {
      warningToast(warningMessage, lang);
      return;
    }

    if (data?.auth?.value === "ip" && data?.authIp && checkIpAgainstRanges(data?.authIp)) {
      return warningToast(notifications?.invalidIp, lang);
    }

    if (
      fiveDollarLogick?.enabled &&
      modal?.prices?.usdAmount < 5 &&
      data?.payment?.code !== "balance"
    ) {
      // setUseFiveDollarLogick(true);
      // setModalOpen(true);
      warningToast(
        `${prices.lowAmount} ${prices.infoModalSelectBalance}`,
        lang
      );
      return null;
      // eslint-disable-next-line brace-style
    }
    // else if (fiveDollarLogick?.enabled && data?.payment?.code === "balance" && !user?.isAuth) {
    //   setModalNotAuthBalance();
    //   setUseFiveDollarLogick(true);
    //   return null;
    // }
    else if (
      data?.payment?.code === "balance" &&
      user?.isAuth &&
      modal?.prices?.amount &&
      userBalance?.balance < modal?.prices?.amount
    ) {
      // setParamsForReload({ data, balance: userBalance?.balance });
      // setIsBalanceCheck(true);
      warningToast(prices.lowBalance.main);
      return null;
    } else if (
      modal?.prices?.minPrice &&
      +modal?.prices?.amount < +modal?.prices?.minPrice &&
      modal?.isPricesLoaded
    ) {
      warningToast(
        `${notifications?.minPrice} ${modal?.prices?.minPrice} ${modal?.prices?.currencyCode}`,
        lang
      );
    } else if (modal?.isPricesLoaded) {
      if (
        isCaptchaEnabled &&
        reCaptchaRef.current &&
        content?.data[lang]?.google_captcha_v2_public_key
      ) {
        setIsFormSubmitting(true);
        reCaptchaRef.current.execute();
      } else {
        setIsFormSubmitting(true);
        createOrder();
      }
      // try {
      //   setIsFormSubmitting(true);

      //   if (useReCaptchaV3) {
      //     let token = "";
      //     if (isCaptchaEnabled) {
      //       token = await executeRecaptcha("e_commerce");
      //     }
      //     const params = combineParams(data, token);

      //     const response = await ApiService.createPayment(params);
      //     if (response && (response?.status < 200 || response?.status >= 300)) {
      //       if (response?.data === "incorrect ip") {
      //         throw notifications?.wrongIp;
      //       }
      //       throw notifications.error;
      //     }

      //     if (response && response?.status === 226) {
      //       throw notifications.registerErrorWrongEmail;
      //     }

      //     if (response && response?.status === 200) {
      //       if (response?.data) {
      //         analitics();
      //         window.location.href = response.data;
      //       } else {
      //         throw notifications.error;
      //       }
      //     }

      //     if (response && response?.status === 202) {
      //       setUseReCaptchaV3(false);
      //       setIsFormSubmitting(false);
      //     }
      //   } else {
      //     const params = combineParams(data, tokenV2, true);
      //     const response = await ApiService.createPayment(params);
      //     if (response && (response?.status < 200 || response?.status >= 300)) {
      //       if (response?.data === "incorrect ip") {
      //         throw notifications?.wrongIp;
      //       }
      //       throw notifications.error;
      //     }

      //     if (response && response?.status === 226) {
      //       throw notifications.registerErrorWrongEmail;
      //     }

      //     if (response && response?.status === 200) {
      //       if (response?.data && response?.status === 200) {
      //         analitics();
      //         setUseReCaptchaV3(true);
      //         window.location.href = response.data;
      //       } else {
      //         throw notifications.error;
      //       }
      //     } else {
      //       throw notifications.error;
      //     }
      //   }
      // } catch (err) {
      //   errorToast(err?.message || err, lang);
      //   setIsFormSubmitting(false);
      // }
    }
  };

  const hendlerSetFormParam = (name, data) => methods.setValue(name, data);

  // const onChange = (value) => {
  //   setTokenV2(value);
  // };

  // Set initial value for rent period
  useEffect(() => {
    if (user?.isAuth) {
      ApiService.getBalance(controller)
        .then((req) => {
          if (req?.data?.success) {
            setUserBalance(req.data);
          }
        })
        .catch();
    }
    if (modal?.paramsForReload?.isSet) {
      const temp = Object.keys(modal?.paramsForReload?.data);
      temp.forEach((item) => {
        if (item !== "payment") {
          methods.setValue(item, modal?.paramsForReload?.data[item]);
        }
      });
      setQuantity(modal?.paramsForReload?.data?.quantity);
      setMobileOperator(modal?.paramsForReload?.data?.mobileOperator);
      resetParamsForReload();
    } else {
      setQuantity(modal?.quantity);
      methods.setValue("rent", modal?.days);
      if (modal?.proxyType === "MOBILE" && countryParams.id) {
        methods.setValue("mobileOperator", modal?.mobileOperators[0]);
        setMobileOperator(modal?.mobileOperators[0]);
      }
      if (modal?.proxyType === "IPv4" && modal?.activeEurope) {
        const sortCountries = getCountries(countries?.data, lang, {
          isEurope: false,
          code: "EUROPE",
          showOnFrontType: modal?.proxyType
        });
        setEuropeCountries(sortCountries);
        methods.setValue(
          "country",
          sortCountries.find((item) => item?.id === modal?.country?.id)
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // display prices logical
  // useEffect(() => {
  //   if (+modal?.prices?.usdAmount && +quantity) {
  //     let price = +(+modal?.prices?.usdAmount / +quantity).toFixed(2);
  //     if (price === 0) {
  //       price = +(+modal?.prices?.usdAmount / +quantity).toFixed(3);
  //     }
  //     setDisplayPrices(price);
  //   } else {
  //     setDisplayPrices("-");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [modal?.prices?.usdAmount, quantity, modal?.paramsForReload?.isSet]);

  const updateCalculating = () => {
    let check = false;

    if (
      countryParams?.id &&
      activeData?.currency?.id &&
      proxyCode &&
      isValidQuantity(proxyCode, quantity) &&
      rent?.id
    ) {
      if (proxyCode === "MOBILE") {
        check = true;
      } else if (asyncGoals?.value && modal?.activePaymentSystem?.id) {
        check = true;
      } else {
        check = false;
      }
    } else {
      check = false;
    }

    if (check) {
      const equalValue = options?.filter(
        ({ label }) =>
          label.toLowerCase() === asyncGoals?.value.toLowerCase().trim()
      );
      fetchPriceCalculationModal({
        projectId:
          (equalValue?.length > 0
            ? equalValue[0]?.value
            : asyncGoals?.value.trim()) || null,
        countryId: countryParams?.id,
        currencyId: activeData?.currency?.id,
        paymentSystemId: modal?.activePaymentSystem?.id || null,
        count: +debouncedQuantity,
        rentPeriodId: rent?.id,
        proxyType: proxyCode,
        mobileOperatorTag: mobileOperator?.value || null,
        rotationMin: modemRotationMin?.min >= 0 ? modemRotationMin?.min : null,
        rotationSelected: Boolean(modemRotationMin?.min >= 0)
      });
    } else {
      clearPrices();
    }
  };

  // Calculate price
  useEffect(() => {
    if (proxyCode === "MOBILE") {
      return;
    }

    updateCalculating();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countryParams.id,
    modal?.activePaymentSystem?.id,
    debouncedQuantity,
    rent?.id,
    asyncGoals?.value,
    options,
    mobileOperator?.value,
    modemRotationMin
  ]);

  useEffect(() => {
    if (proxyCode !== "MOBILE") {
      return;
    }

    updateCalculating();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modal?.activePaymentSystem?.id,
    debouncedQuantity,
    rent?.id,
    asyncGoals?.value,
    options,
    modemRotationMin
  ]);

  // Handling error
  if (modal?.pricesError) {
    errorToast(notifications.error, lang);
  }
  const checkQuantity = () => {
    if (!isValidQuantity(proxyCode, debouncedQuantity)) {
      getQuantityWarningMessage(proxyCode, lang);
    }
  };

  return (
    <div>
      {/* <div className="order__form__top">{orderForm?.title}</div> */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="order__form">
          <Countries
            proxyCode={proxyCode}
            setCountryParams={setCountryParams}
            setMobileOperators={setMobileOperators}
          />
          {proxyCode === "IPv6" && (
            <ModalSelect
              name="proxyProtocol"
              rules={{ required: true }}
              options={protocols}
              placeholder={formPlaceholders?.protocol}
              onChange={(i) => {
                setProxyProtocol(i?.value);
              }}
            />
          )}

          <ModalSelect
            ref={goalsSelect}
            name="asyncGoals"
            rules={{
              required: true
            }}
            placeholder={formPlaceholders?.proxyGoalField?.select}
            isAsync
            isSelectValid
            setOptions={setOptions}
          />
          <div className="order__form-row order__form-dynamic-row">
            <ModalInput
              type="text"
              name="quantity"
              proxyType={modal?.proxyType}
              placeholder={formPlaceholders?.quantity2}
              placeholderNoData={formPlaceholders?.quantity1}
              defaultValue={
                // modal?.paramsForReload?.data?.quantity || modal?.quantity
                modal?.quantity
              }
              handler={(data) => setQuantity(data)}
              quantity
              rules={{
                required: true,
                min: proxyCode === "IPv6" ? 10 : 1
              }}
            />
            <ModalSelect
              name="rent"
              rules={{ required: true }}
              options={rentPeriods}
              placeholder={formPlaceholders?.rentPeriod}
              onChange={(value) => {
                setRentPeriod(value);
              }}
            />
          </div>
          <MobileoperatorParams
            proxyCode={proxyCode}
            mobileOperator={mobileOperator}
            mobileOperators={mobileOperators}
            setMobileOperator={setMobileOperator}
            setModemRotationMin={setModemRotationMin}
          />
          <AuthParams />
          <ClientEmail methods={methods} />
          <PaymentSystem data={payment} setData={hendlerSetFormParam} />
          <AgreeWithRules
            defaultValue={isAgreeWithConditions}
            handler={() => setIsAgreeWithConditions((prev) => !prev)}
          />
          {/* <ModalInput
              type="checkbox"
              name="policy"
              defaultValue
              rules={{
                required: true
              }}
            /> */}

          {modal?.prices?.priceMobileRotation &&
            modal?.proxyType === "MOBILE" && (
              <div className="order__form-prices">
                <div className="order__form-prices--pieces">
                  {orderForm?.rotation} <span>{modemRotationMin?.label}</span>
                </div>
                <div className="order__form-prices--full">
                  {modal?.prices?.priceMobileRotation}
                  <span> {modal?.prices?.currencyCode}</span>
                </div>
              </div>
            )}
          {modal?.prices?.priceMobileOperator &&
            modal?.proxyType === "MOBILE" && (
              <div className="order__form-prices">
                <div className="order__form-prices--pieces">
                  {orderForm?.operator} <span>{mobileOperator?.label}</span>
                </div>
                <div className="order__form-prices--full">
                  {modal?.prices?.priceMobileOperator}
                  <span> {modal?.prices?.currencyCode}</span>
                </div>
              </div>
            )}
          <div className="order__form-prices">
            <div className="order__form-prices--pieces">
              {/*{displayPrices}*/}
              {modal?.prices?.usdPrice && modal?.prices?.usdPrice !== "-" ? `$${modal?.prices?.usdPrice}` : "$0"}
              <span>
                {/*{modal?.prices?.currencyCode} / {formPlaceholders?.quantity2}*/}
                / IP
              </span>
            </div>
            <div className="order__form-prices--full">
              <span className="order__form-prices--full-name">
                {" "}
                {orderForm?.fullPrice}:
              </span>
              <span className="order__form-prices--full-number">
                {modal?.prices?.usdAmount && modal?.prices?.usdAmount !== "-" ? `$${modal?.prices?.usdAmount}` : "$0"}
              </span>
              {/*{modal?.prices?.currencyCode}*/}
            </div>
          </div>
          {isCaptchaEnabled &&
            content?.data[lang]?.google_captcha_v2_public_key && (
              // <div
              //   className={`modal__reCaptchaV2 modal__reCaptchaV2${
              //     useReCaptchaV3 ? "-display-none" : "-display-block"
              //   }`}
              // >
              <ReCAPTCHA
                ref={reCaptchaRef}
                size="invisible"
                sitekey={content?.data[lang]?.google_captcha_v2_public_key}
                onChange={createOrder}
              />
              // </div>
            )}
          <Button
            className="order__form-btn"
            type="submit"
            tab="buy-proxy"
            onClick={checkQuantity}
            disabled={!useReCaptchaV3 && !tokenV2}
          >
            {orderForm?.payBtn}
          </Button>
        </form>
      </FormProvider>
      {isFormSubmitting && <Loading orderForm />}
    </div>
  );
};
